import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import LoadingSpin from 'react-loading-spin';

class SpinModal extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      smShow: false,
      email: "",
      password: "",
      mode: "login",
      token: "",
      user: "",
      error: false,
    };
  }

  setMode = (mode) => {
    this.setState({
      mode,
    });
  };



  render() {
    return (
        <Modal
          size="sm"
          backdrop="static"
          show={this.props.showModal}
          centered
        >
        <Modal.Body style={{"textAlign": "center"}}><LoadingSpin primaryColor = '#ef8e00'/></Modal.Body>
        </Modal>
    );
  }
}

export default SpinModal;
