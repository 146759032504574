import React, { useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ProfileImageWithDefault from "./ProfileImageWithDefault";
import { useTranslation } from "react-i18next";
import Input from "./Input";
import { updateUser, deleteUser, getAddressByCEP } from "../api/apiCalls";
import { useApiProgress } from "../shared/ApiProgress";
import ButtonWithProgress from "./ButtonWithProgress";
import { updateSuccess, logoutSuccess } from "../redux/authActions";
import SelectGender from "../components/SelectGender";
import SelectState from "../components/SelectState";
import SelectPaymentType from "../components/SelectPaymentType";
import Modal from "./Modal";
import SpinModal from "../pages/SpinModal";

const ProfileCard = (props) => {
  const [inEditMode, setInEditMode] = useState(false);
  const [updatedUsername, setUpdatedUsername] = useState();
  const { id: loggedInUserId } = useSelector((store) => ({ id: store.id }));
  const { userid } = useParams();
  const [user, setUser] = useState({});
  const [editable, setEditable] = useState(false);
  const [newImage, setNewImage] = useState();
  const [validationErrors, setValidationErrors] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [showSpin, setShowSpin] = useState(false);
  const [enableFields, setEnableFields] = useState(false);
  const history = useHistory();
  const [streetF, setStreetF] = useState();
  const [stateF, setStateF] = useState();
  const [cityF, setCityF] = useState();
  const [districtF, setDistrictF] = useState();
      
  const [form, setForm] = useState({
    firstname: user.firstname,
    lastname: user.lastname,
    city: user.city,
    state: user.state,
    birthdate: user.birthdate,
    gender: user.gender,
    cpf: user.cpf,
    cep: user.cep,
    street : user.street,
    number: user.number,
    district: user.district,
    paymentType: user.paymentType,
    pjNomeFantasia: user.pjNomeFantasia,
    pjRazaoSocial: user.pjRazaoSocial,
    pjcnpj: user.pjcnpj,
    pjFundacao: user.pjFundacao,
  });

  const closeSpin = () => {
    setShowSpin(false);
  };
  const openSpin = () => {
    setShowSpin(true);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setForm((previousForm) => ({ ...previousForm, [name]: value }));
  };

  const onChangeStreet = (event) => {
    const { name, value } = event.target;
    setForm((previousForm) => ({ ...previousForm, [name]: value }));
    setStreetF(value);    
  };

  const onChangeDistrict = (event) => {
    const { name, value } = event.target;
    setForm((previousForm) => ({ ...previousForm, [name]: value }));
    setDistrictF(value);    
  };

  const onChangeCity = (event) => {
    const { name, value } = event.target;
    setForm((previousForm) => ({ ...previousForm, [name]: value }));
    setCityF(value);    
  };

  const onChangeState = (event) => {
    const { name, value } = event.target;
    setForm((previousForm) => ({ ...previousForm, [name]: value }));
    setStateF(value);    
  };

  const cpfMask = (value) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  };

  const cnpjMask = (value) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1/$2")
      .replace(/(\d{4})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  };

  const cepMask = (value) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{3})\d+?$/, "$1");
  };

  const onChangeCPF = (event) => {
    const name = event.target.name;
    const value = cpfMask(event.target.value);
    event.target.value = cpfMask(event.target.value);
    setForm((previousForm) => ({ ...previousForm, [name]: value }));
  };

   const onBlurCEP = async (event) => {
    event.preventDefault();
    openSpin();
    try {
      await getAddressByCEP(
        event.target.value.replace(".", "").replace("-", "")
      ).then ( (result) => {
        form.street =  result.data.logradouro;
        form.district = result.data.bairro;
        form.state = result.data.uf;
        form.city = result.data.localidade;
        
        setStreetF(result.data.logradouro);
        setDistrictF(result.data.bairro);
        setCityF(result.data.localidade);
        setStateF(result.data.uf);
  
      })
    } catch (error){
      closeSpin();
    }
    closeSpin();  
  };

  const onChangeCEP = (event) => {
    const name = event.target.name;
    const value = cepMask(event.target.value);
    event.target.value = cepMask(event.target.value);
    setForm((previousForm) => ({ ...previousForm, [name]: value }));
  };

  const onChangeCNPJ = (event) => {
    const name = event.target.name;
    const value = cnpjMask(event.target.value);
    event.target.value = cnpjMask(event.target.value);
    setForm((previousForm) => ({ ...previousForm, [name]: value }));
  };

 useEffect(() => {
    setUser(props.user);
  }, [props.user]);


  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    setEditable(userid == loggedInUserId);
  }, [loggedInUserId, userid]);

  useEffect(() => {
    setValidationErrors((previousValidationErrors) => ({
      ...previousValidationErrors,
      username: undefined,
    }));
  }, [updatedUsername]);

  useEffect(() => {
    setValidationErrors((previousValidationErrors) => ({
      ...previousValidationErrors,
      image: undefined,
    }));
  }, [newImage]);

  let {
    id,
    username,
    image,
    firstname,
    lastname,
    gender,
    birthdate,
    cpf,
    cep,
    street,
    number,
    district,
    city,
    state,
    paymentType,
    pjNomeFantasia,
    pjRazaoSocial,
    pjcnpj,
    pjFundacao,
  } = user;

  const pendingApiCallDeleteUser = useApiProgress(
    "delete",
    `/api/1.0/users/${id}`,
    true
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (!inEditMode) {
      setUpdatedUsername(undefined);
      setNewImage(undefined);
    } else {
      setUpdatedUsername(username);
    }
  }, [inEditMode, username]);

  const onClickSave = async (event) => {
    event.preventDefault();
    openSpin();

    let image;
    if (newImage) {
      image = newImage.split(",")[1];
    }

    const body = {
      firstname: form.firstname !== undefined ? form.firstname : user.firstname,
      lastname: form.lastname !== undefined ? form.lastname : user.lastname,
      city: form.city !== undefined ? form.city : user.city,
      state: form.state !== undefined ? form.state : user.state,
      birthdate: form.birthdate !== undefined ? form.birthdate : user.birthdate,
      gender: form.gender !== undefined ? form.gender : user.gender,
      cpf: form.cpf !== undefined ? form.cpf : user.cpf,
      cep: form.cep !== undefined ? form.cep : user.cep,
      street: form.street !== undefined ? form.street : user.street,
      number: form.number !== undefined ? form.number : user.number,
      district: form.district !== undefined ? form.district : user.district,
      pjNomeFantasia:
        form.pjNomeFantasia !== undefined
          ? form.pjNomeFantasia
          : user.pjNomeFantasia,
      pjRazaoSocial:
        form.pjRazaoSocial !== undefined
          ? form.pjRazaoSocial
          : user.pjRazaoSocial,
      pjcnpj: form.pjcnpj !== undefined ? form.pjcnpj : user.pjcnpj,
      pjFundacao:
        form.pjFundacao !== undefined ? form.pjFundacao : user.pjFundacao,
      paymentType:
        form.paymentType !== undefined ? form.paymentType : user.paymentType,
      image,
    };
    console.log(body);

    try {
      const response = await updateUser(userid, body);
      setInEditMode(false);
      if (response.data) {
        console.log(response.data);
        setUser(response.data);
        dispatch(updateSuccess(response.data));
      } else {
        const upToDate = {
          ...user,
          username: updatedUsername,
        };
        setUser(upToDate);
        dispatch(updateSuccess(upToDate));
      }
    } catch (error) {
      closeSpin();
      if (error.response.status === 400) {
        setValidationErrors(error.response.data.validationErrors);
      } else {
        setValidationErrors({ image: error.response.data.message });
      }
    }
    closeSpin();
  };

  const onChangeFile = (event) => {
    if (event.target.files.length < 1) {
      return;
    }
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      setNewImage(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  };

  const onClickCancel = () => {
    setModalVisible(false);
  };

  const onClickDeleteUser = async () => {
    setModalVisible(false);
    openSpin();
    await deleteUser(id);
    closeSpin();
    dispatch(logoutSuccess());
    history.push("/");
  };
  const pendingApiCall = useApiProgress("put", "/api/1.0/users/" + userid);

  const {
    username: usernameError,
    image: imageError,
    firstname: firstnameError,
    lastname: lastnameError,
    gender: genderError,
    birthdate: birthdateError,
    cpf: cpfError,
    cep: cepError,
    street: streetError,
    number: numberError,
    district: districtError,
    city: cityError,
    state: stateError,
    paymentType: paymentTypeError,
    pjNomeFantasia: pjNomeFantasiaError,
    pjRazaoSocial: pjRazaoSocialError,
    pjcnpj: pjcnpjError,
    pjFundacao: pjFundacaoError,
  } = validationErrors;

  return ( 
    <div className="card">
      <SpinModal showModal={showSpin} />
      <div className="card-header text-center">
        <ProfileImageWithDefault
          className="rounded-circle shadow"
          width="200"
          height="200"
          alt={`${username} profile`}
          image={image}
          tempimage={newImage}
        />
      </div>
      <div className="card-body">
        {!inEditMode && (
          <div className="text-center">
            <h3>
              {firstname} {lastname}
            </h3>
            {editable && (
              <>
                <div className="text-center">
                  <button
                    className="btn btn-edit-profile d-inline-flex"
                    onClick={() => setInEditMode(true)}
                  >
                    {t("Editar")}
                  </button>
                  &nbsp;
                  <Link
                    className="btn btn-edit-profile d-inline-flex"
                    to="/user/change/password"
                  >
                    Alterar Senha
                  </Link>
                </div>
                <div className="pt-2">
                  <button
                    className="btn d-inline-flex"
                    onClick={() => setModalVisible(true)}
                  >
                    <i className="material-icons">directions_run</i>
                    {t("Deletar Usuário")}
                  </button>
                </div>
              </>
            )}
          </div>
        )}
        {inEditMode && (
          <div>
            <Input
              label={t("Nome")}
              defaultValue={firstname}
              onChange={onChange}
              error={usernameError}
              name="firstname"
            />
            <Input
              label={t("Sobrenome")}
              defaultValue={lastname}
              onChange={onChange}
              error={usernameError}
              name="lastname"
            />
            <Input
              name="cpf"
              label={t("CPF")}
              error={cpfError}
              onChange={onChangeCPF}
              defaultValue={cpf}
            />
            <Input
              name="cep"
              label={t("CEP")}
              error={cepError}
              onBlur={onBlurCEP}
              onChange={onChangeCEP}
              defaultValue={cep}
            />
            <Input
              name="street"
              label={t("Endereço")}
              error={streetError}
              onChange={onChangeStreet}
              defaultValue={street}
              value={streetF}
            />
            <Input
              name="number"
              label={t("Número")}
              error={numberError}
              onChange={onChange}
              defaultValue={number}
            />
            <Input
              name="district"
              label={t("Bairro")}
              error={districtError}
              onChange={onChangeDistrict}
              defaultValue={district}
              value={districtF}
            />
            <Input
              label={t("Cidade")}
              defaultValue={city}
              onChange={onChangeCity}
              error={usernameError}
              name="city"
              value={cityF}
            />
            <SelectState
              name="state"
              label={t("Estado")}
              defaultValue={state}
              onChange={onChangeState}
              value={stateF}
            />
            <Input
              name="birthdate"
              label={t("Data de Nascimento")}
              defaultValue={birthdate}
              onChange={onChange}
              type="date"
            />
            <SelectGender
              name="gender"
              label={t("Gênero")}
              defaultValue={gender}
              onChange={onChange}
            />
            <SelectPaymentType
              name="paymentType"
              label={t("Forma de Pagamento")}
              error={paymentTypeError}
              onChange={onChange}
              defaultValue={paymentType}
            />
            {enableFields ? (
              <>
                <Input
                  name="pjNomeFantasia"
                  label={t("Nome Fantasia")}
                  error={pjNomeFantasiaError}
                  onChange={onChange}
                  defaultValue={pjNomeFantasia}
                />
                <Input
                  name="pjRazaoSocial"
                  label={t("Razão Social")}
                  error={pjRazaoSocialError}
                  onChange={onChange}
                  defaultValue={pjRazaoSocial}
                />
                <Input
                  name="pjcnpj"
                  label={t("CNPJ")}
                  error={pjcnpjError}
                  onChange={onChangeCNPJ}
                  defaultValue={pjcnpj}
                />
                <Input
                  name="pjFundacao"
                  label={t("Data de Fundação")}
                  error={pjFundacaoError}
                  onChange={onChange}
                  type="date"
                  defaultValue={pjFundacao}
                />
              </>
            ) : (
              ""
            )}
            <Input
              type="file"
              onChange={onChangeFile}
              error={imageError}
              label="Foto do Perfil"
            />
            <div className="text-center">
              <ButtonWithProgress
                className="btn btn-primary d-inline-flex"
                onClick={onClickSave}
                disabled={pendingApiCall}
                pendingApiCall={pendingApiCall}
                text={<>{t("Salvar")}</>}
              />
              <button
                className="btn btn-light d-inline-flex ml-1"
                onClick={() => setInEditMode(false)}
                disabled={pendingApiCall}
              >
                <i className="material-icons">close</i>
                {t("Cancelar")}
              </button>
            </div>
          </div>
        )}
      </div>
      <Modal
        visible={modalVisible}
        title={t("Deletar Usuário")}
        okButton={t("Deletar Usuário")}
        onClickCancel={onClickCancel}
        onClickOk={onClickDeleteUser}
        message={t("Você tem certeza que deseja deletar seu usuário?")}
        pendingApiCall={pendingApiCallDeleteUser}
      />
    </div>
  );
};

export default ProfileCard;
