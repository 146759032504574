import React, { useState, useEffect } from "react";
import Input from "../components/Input";
import SelectGender from "../components/SelectGender";
import SelectState from "../components/SelectState";
import SelectPaymentType from "../components/SelectPaymentType";
import { useTranslation } from "react-i18next";
import ButtonWithProgress from "../components/ButtonWithProgress";
import { useApiProgress } from "../shared/ApiProgress";
import { updateScreenPJ } from "../redux/authActions";
import {
  signup,
  signUpSqedUser,
  loginSqedUser,
  updateTokenSqedUser,
  sqedUserValidate,
  getAddressByCEP
} from "../api/apiCalls";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./UserSignupPage.css";
import LoginForm from "./LoginSqed";
import SpinModal from "./SpinModal";

import { Button } from "react-bootstrap";

const UserSignupPage = (props) => {
  const [form, setForm] = useState({
    username: null,
    email: null,
    password: null,
    passwordRepeat: null,
    tokenSqed: null,
    plan: props.location.search.split("=")[1],
  });
  const [errors, setErrors] = useState({});
  const [externalFailure, setExternalFailure] = useState(false);
  const [userSqedExists, setUserSqedExists] = useState(false);
  const [readyForActivation, setReadyForActivation] = useState(false);
  const [showLoginSqed, setShowLoginSqed] = useState(false);
  const [showSpin, setShowSpin] = useState(false);
  const [plano, setPlano] = useState();
  const [enableFields, setEnableFields] = useState(false);
  const dispatch = useDispatch();

  const close = () => {
    setShowLoginSqed(false);
  };
  const open = () => {
    setUserSqedExists();
    setShowLoginSqed(true);
  };

  const closeSpin = () => {
    setShowSpin(false);
  };
  const openSpin = () => {
    setShowSpin(true);
  };

  const cpfMask = value => {
    return value
      .replace(/\D/g, '') 
      .replace(/(\d{3})(\d)/, '$1.$2') 
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') 
  }

  const cnpjMask = value => {
    return value
      .replace(/\D/g, '') 
      .replace(/(\d{2})(\d)/, '$1.$2') 
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1/$2')
      .replace(/(\d{4})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') 
  }

  const cepMask = value => {
    return value
      .replace(/\D/g, '') 
      .replace(/(\d{2})(\d)/, '$1.$2') 
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1') 
  }

  const onBlurCEP = async (event) => {
    event.preventDefault();
    openSpin();
    try {
      await getAddressByCEP(
        event.target.value.replace(".", "").replace("-", "")
      ).then ( (result) => {
        form.street =  result.data.logradouro;
        form.district = result.data.bairro;
        form.state = result.data.uf;
        form.city = result.data.localidade; 
      })
    } catch (error){
      closeSpin();
    }
    closeSpin();  
  };

  const onChangeCPF = (event) => {
    const name = event.target.name;
    const value  = cpfMask(event.target.value);
    event.target.value = cpfMask(event.target.value);

    setErrors((previousErrors) => ({ ...previousErrors, [name]: undefined }));
    setForm((previousForm) => ({ ...previousForm, [name]: value }));
  };

  const onChangeCEP = (event) => {
    const name = event.target.name;
    const value  = cepMask(event.target.value);
    event.target.value = cepMask(event.target.value);

    setErrors((previousErrors) => ({ ...previousErrors, [name]: undefined }));
    setForm((previousForm) => ({ ...previousForm, [name]: value }));
  };

  const onChangeCNPJ = (event) => {
    const name = event.target.name;
    const value  = cnpjMask(event.target.value);
    event.target.value = cnpjMask(event.target.value);

    setErrors((previousErrors) => ({ ...previousErrors, [name]: undefined }));
    setForm((previousForm) => ({ ...previousForm, [name]: value }));
  };

  const onChange = (event) => {
    const { name, value } = event.target;


    setErrors((previousErrors) => ({ ...previousErrors, [name]: undefined }));
    setForm((previousForm) => ({ ...previousForm, [name]: value }));
  };
  const { t } = useTranslation();

  useEffect(() => {
    setPlano(props.location.search.split("=")[1]);
  }, []);

  const onClickSignup = async (event) => {
    event.preventDefault();
    openSpin();

    const {
      email,
      password,
      firstname,
      lastname,
      cpf,
      cep,
      street,
      number,
      district,
      city,
      state,
      birthdate,
      gender,
      plan,
      tokenSqed,
      paymentType,
      pjNomeFantasia,
      pjRazaoSocial,
      pjcnpj,
      pjFundacao,
    } = form;

    const body = {
      username: email,
      email,
      password,
      firstname,
      lastname,
      cpf,
      cep,
      street,
      number,
      district,
      city,
      state,
      birthdate,
      gender,
      plan: props.location.search.split("=")[1],
      tokenSqed,
      paymentType,
      pjNomeFantasia,
      pjRazaoSocial,
      pjcnpj,
      pjFundacao,
    };
    setExternalFailure();
    setUserSqedExists();
    setErrors({});
    try {     
      
      if (!body.tokenSqed) {
        sqedUserValidate(body.email).then( async (existsResult) => {
          console.log(existsResult);
          if (!existsResult.data.exists){
            await signUpSqedUser(body).then( async () => {
              const bodySqed = {
                email: body.email,
                password: body.password,
              };
              await signup(body).then(async (resultPortal) => {
                await loginSqedUser(bodySqed).then(async (result) => {
                  body.tokenSqed =result.data.token.token;                  
                  await updateTokenSqedUser(resultPortal.data.idUser, { tokenSqed: result.data.token.token });
                  closeSpin();
                  setReadyForActivation(true);
                  return result.data.token;
                }).catch(()=> {
                  closeSpin();
                  setExternalFailure(true);
                });
              });
              
            }).catch((error)=> {
              const errorStatus = error.response.status;
              
              if (errorStatus === 400) {
                // validation
                setErrors(error.response.data.validationErrors);
              } else if (errorStatus === 502) {
                setExternalFailure(true);
              }
              closeSpin();
              
            });
          } else {
            closeSpin();
            setUserSqedExists(true);
            
          }

        })
          
 
      } else {
        const resultPortal = await signup(body);
        closeSpin();
        setReadyForActivation(true);        
      } 
      
    } catch (error) {
      console.log(error);
      const errorStatus = error.response.status;
      
      if (errorStatus === 400) {
        // validation
        setErrors(error.response.data.validationErrors);
      } else if (errorStatus === 502) {
        setExternalFailure(true);
      }
      closeSpin();
    }
    
  };

  const {
    username: usernameError,
    email: emailError,
    city: cityError,
    firstname: firstnameError,
    lastname: lastnameError,
    cpf: cpfError,
    cep: cepError,
    street: streetError,
    number: numberError, 
    district: districtError,
    gender: genderError,
    birthdate: birthdateError,
    state: stateError,
    password: passwordError,
    paymentType: paymentTypeError,
    pjNomeFantasia: pjNomeFantasiaError,
    pjRazaoSocial: pjRazaoSocialError,
    pjcnpj: pjcnpjError,
    pjFundacao: pjFundacaoError,
  } = errors;
  const pendingApiCallSignup = useApiProgress("post", "/api/1.0/users");
  const pendingApiCallLogin = useApiProgress("post", "/api/1.0/auth");

  const pendingApiCall = pendingApiCallSignup || pendingApiCallLogin;

  let passwordRepeatError;
  if (form.password !== form.passwordRepeat) {
    passwordRepeatError = t("Password mismatch");
  }

  if (readyForActivation) {
    return (
      <div className="container">
        <div className="alert alert-success text-center">
          <div>
            <i className="material-icons" style={{ fontSize: "48px" }}>
              done_outline
            </i>
          </div>
          {t("Foi enviado um e-mail para ativação da sua conta", {
            address: form.email,
          })}
        </div>
      </div>
    );
  }

  const handleChange = async (event) => {
    form.checked= !form.checked;
    dispatch(updateScreenPJ());
  }

  const register = (
    email,
    firstname,
    lastname,
    birthdate,
    gender,
    tokenSqed,
    cpf,
    cep,
    street,
    number,
    city,
    district,
    paymentType,
    pjNomeFantasia,
    pjRazaoSocial,
    pjcnpj,
    pjFundacao,
    checked
  ) => {
    setForm({
      email: email,
      firstname: firstname,
      lastname: lastname,
      birthdate: birthdate,
      gender: gender,
      tokenSqed: tokenSqed,
      cpf: cpf,
      cep: cep,
      street: street,
      number: number,
      city: city,
      district: district,
      paymentType: paymentType,
      pjNomeFantasia,
      pjRazaoSocial,
      pjcnpj,
      pjFundacao,
      checked
    });
    closeSpin();
  };

  return (
    <div className="container registrybox">
      <SpinModal showModal={showSpin} />
      <form>
        <div className="row">
          {!form.tokenSqed ? (
            <div className="col text-center">
              Já sou um usuário da Sqed, desejo vincular minha conta!
              <br />
              <Button type="button" className="btn btn-primary" onClick={open}>
                Vincular conta Sqed
              </Button>
              <LoginForm
                showModal={showLoginSqed}
                onClose={close}
                onErrorOpen={open}
                onFind={openSpin}
                onFindClose={closeSpin}
                onRegister={register}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="col"></div>
        {!form.tokenSqed ? <hr /> : ""}
        <div className="row">
          <div className="col  registryHidden col-md-2">

          </div>
          <div className="col">
            <h2 className="text-center">{t("Cadastre-se")}</h2>

            <div className="card-body mt-2">
              <Input
                name="email"
                label={t("E-mail")}
                error={emailError}
                onChange={onChange}
                defaultValue={form.email}
              />
              <Input
                name="firstname"
                label={t("Nome")}
                error={firstnameError}
                onChange={onChange}
                defaultValue={form.firstname}
              />
              <Input
                name="lastname"
                label={t("Sobrenome")}
                error={lastnameError}
                onChange={onChange}
                defaultValue={form.lastname}
              />
              <Input
                name="cpf"
                label={t("CPF")}
                error={cpfError}
                onChange={onChangeCPF}
                defaultValue={form.cpf}
              />
              {plano && plano != 'Bee' ?
              <>              
              <Input
                name="cep"
                label={t("CEP")}
                error={cepError}
                onChange={onChangeCEP}
                defaultValue={form.cep}
                onBlur={onBlurCEP}
              />
              <Input
                name="street"
                label={t("Endereço")}
                error={streetError}
                onChange={onChange}
                value={form.street}
              />
              <Input
                name="number"
                label={t("Número")}
                error={numberError}
                onChange={onChange}
                defaultValue={form.number}
              />
              <Input
                name="district"
                label={t("Bairro")}
                error={districtError}
                onChange={onChange}
                value={form.district}
              />              
              <Input
                name="city"
                label={t("Cidade")}
                error={cityError}
                onChange={onChange}
                value={form.city}
              />
              <SelectState
                name="state"
                label={t("Estado")}
                error={stateError}
                onChange={onChange}
                value={form.state}
              />
              </>
              :''}
              
              <form>
              {plano && plano != 'Bee' ?
                <Input
                  name="birthdate"
                  label={t("Data de Nascimento")}
                  error={birthdateError}
                  onChange={onChange}
                  dateFormat="dd/MM/yyyy"
                  locate="pt-br"
                  type="date"
                  defaultValue={form.birthdate}
                />
              :''}
                <SelectGender
                  name="gender"
                  label={t("Gênero")}
                  error={genderError}
                  onChange={onChange}
                  defaultValue={form.gender}
                />
                {plano && plano != 'Bee' ?
                <SelectPaymentType
                  name="paymentType"
                  label={t("Forma de Pagamento")}
                  error={paymentTypeError}
                  onChange={onChange}
                  defaultValue={form.paymentType}
                />
                :''}
                <Input
                  name="password"
                  label={t("Senha")}
                  error={passwordError}
                  onChange={onChange}
                  type="password"
                />
                <Input
                  name="passwordRepeat"
                  label={t("Repita a Senha")}
                  error={passwordRepeatError}
                  onChange={onChange}
                  type="password"
                />
                {enableFields && plano && plano != 'Bee' ?
                <>
                <hr/>
                <div style={{backgroundColor: "#cccccc", height:"30px"}}>
                  <span style={{"padding": "10px"}}>
                   <input type="checkbox" onChange={handleChange} checked={form.checked} /> Vinculado à uma empresa?
                  </span>
                </div>
                <hr/>
                </>
                :''}
                {form.checked && <div>
                <Input
                  name="pjNomeFantasia"
                  label={t("Nome Fantasia")}
                  error={pjNomeFantasiaError}
                  onChange={onChange}
                  defaultValue={form.pjNomeFantasia}
                />
                <Input
                  name="pjRazaoSocial"
                  label={t("Razão Social")}
                  error={pjRazaoSocialError}
                  onChange={onChange}
                  defaultValue={form.pjRazaoSocial}
                />
                <Input
                  name="pjcnpj"
                  label={t("CNPJ")}
                  error={pjcnpjError}
                  onChange={onChangeCNPJ}
                  defaultValue={form.pjcnpj}
                />
                <Input
                  name="pjFundacao"
                  label={t("Data de Fundação")}
                  error={pjFundacaoError}
                  onChange={onChange}
                  type="date"
                  defaultValue={form.pjFundacao}
                /> 
                </div>}
                {externalFailure && (
                  <div className="alert alert-danger text-center">
                    <div>
                      <i
                        className="material-icons"
                        style={{ fontSize: "48px" }}
                      >
                        error
                      </i>
                    </div>
                    {t("Ocorreu um erro interno, por favor, tente mais tarde")}
                  </div>
                )}
                {userSqedExists && (
                  <div className="alert alert-danger text-center">
                    <div>
                      <i
                        className="material-icons"
                        style={{ fontSize: "48px" }}
                      >
                        error
                      </i>
                    </div>
                    {t("Usuário informado existe na Sqed, utilize o botão 'Vincular conta Sqed' para criar o vínculo entre usuários!")}
                  </div>
                )}
                <div className="text-center">
                  <Link to={"/"} className="btn">
                    Cancelar
                  </Link>
                  <ButtonWithProgress
                    onClick={onClickSignup}
                    disabled={
                      pendingApiCall || passwordRepeatError !== undefined
                    }
                    pendingApiCall={pendingApiCall}
                    text={t("Registrar")}
                  />
                </div>
              </form>
              <div className="text-center mt-3">
                <Link to="/login">{t("Já possui cadastro?")}</Link>
              </div>
            </div>
          </div>
          <div className="row">
          <div className="col registryHidden col-md-2"></div>
          <div className="col text-center" style={{fontColor: "#ccc", width:"50%", fontSize: "12px"}}>
          Os dados acima são necessários para o cadastro do usuário como membro do Co.nectar Hub e para a utilização dos serviços do plano escolhido. Ao finalizar seu cadastro, você está ciente e concorda com o tratamento de seus dados, nos termos da Lei Geral de Proteção de Dados (Lei nº 13/709/2018)
          </div>
          </div>
          <div className="col registryHidden  col-md-2">
          
          </div>
        </div>        
      </form>
    </div>
  );
};

export default UserSignupPage;
