import * as ACTIONS from "./Constants";
import { login, logout } from "../api/apiCalls";

export const logoutSuccess = () => {
  return async function (dispatch) {
    try {
      await logout();
    } catch (err) {
      console.log("backend not ready with logout");
    }
    dispatch({
      type: ACTIONS.LOGOUT_SUCCESS,
    });
  };
};

export const loginSuccess = (authState) => {
  return {
    type: ACTIONS.LOGIN_SUCCESS,
    payload: authState,
  };
};

export const updateSuccess = ({
  firstname,
  lastname,
  city,
  state,
  gender,
  birthdate,
  image,
}) => {
  return {
    type: ACTIONS.UPDATE_SUCCESS,
    payload: {
      firstname,
      lastname,
      city,
      state,
      gender,
      birthdate,
      image,
    },
  };
};

export const updatePlanSuccess = ({ plan }) => {
  return {
    type: ACTIONS.UPDATE_SUCCESS,
    payload: {
      plan,
    },
  };
};

export const updateScreenPJ = () => {
  return {
    type: ACTIONS.UPDATE_SUCCESS,
    payload: {
    },
  };
};

export const loginHandler = (credentials) => {
  return async function (dispatch) {
    const response = await login(credentials);
    const authState = {
      id: response.data.id,
      username: response.data.username,
      firstname: response.data.firstname,
      lastname: response.data.lastname,
      image: response.data.image,
      token: response.data.token,
      tokenSqed: response.data.tokenSqed,
      password: response.data.password,
      email: response.data.email,
      plan: response.data.plan,
      admin: response.data.admin,
      ...credentials,
    };
    dispatch(loginSuccess(authState));
    return response;
  };
};
