import React, { useState, useEffect }  from "react";
import { Link } from "react-router-dom";
import "./UserSignupPlanPage.css";
import { getAllPlans } from "../api/apiCalls";
import SpinModal from "./SpinModal";

const UserSignupPlanPage = (props) => {
  const [plans, setPlans] = useState(null);
  const [showSpin, setShowSpin] = useState(false);
  useEffect(() => {
    loadPlans();
  }, []);

  const closeSpin = () => {
    setShowSpin(false);
  };
  const openSpin = () => {
    setShowSpin(true);
  };

  const loadPlans = async () => {
    openSpin();
    try {
      const response = await getAllPlans();
      setPlans(response.data.content);
    } catch (error) {
      closeSpin();
    }
    closeSpin();
  };

  return (
    <div className="container registrybox">
      <SpinModal showModal={showSpin} />
      <div className="container bootstrap snippets bootdey">
        <div className="row content">
        {plans != null
              ? plans &&
                plans.map((plan) => (
          <div className="col-md-4  text-center" style={{display: "flex", flexDirection: "column"}}>
            <div className="plan" style={{height: '400px'}}>
              <h2 className="bronze-text">{plan.name}</h2>
              <p className="bronze-text price">{plan.value}</p>
              <p style={{whiteSpace: 'pre-line'}}>{plan.description}</p>
              
              <Link style={{ position: "absolute", bottom:"20px",  left:"10%", right:"10%"}} className="btn btn-pricing" to={`/signup?plan=${plan.tag}`}>
                Selecionar
              </Link>
              
            </div>
            <div></div>
          </div>) )
          :""}
        </div>
      </div>
    </div>
  );
};

export default UserSignupPlanPage;
