import React, { useState, useEffect } from 'react';
import Input from '../components/Input';
import { useTranslation } from 'react-i18next';
import ButtonWithProgress from '../components/ButtonWithProgress';
import { useApiProgress } from '../shared/ApiProgress';
import { useDispatch } from 'react-redux';
import { loginHandler } from '../redux/authActions';
import { activate } from '../api/apiCalls';
import Spinner from '../components/Spinner';
import { Link } from 'react-router-dom';
import './LoginPage.css';
import SpinModal from "./SpinModal";

const LoginPage = props => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const [showSpin, setShowSpin] = useState(false);
  const [accountStatus, setAccountStatus] = useState('done') // active, failed
  const pendingApiCall = useApiProgress('post', '/api/1.0/auth');

  const dispatch = useDispatch();

  const closeSpin = () => {
    setShowSpin(false);
  };
  const openSpin = () => {
    setShowSpin(true);
  };

  useEffect(() => {
    const sendActivation = async (token) => {
      try {
        await activate(token)
        setAccountStatus('active');
        setTimeout(() => {
          setAccountStatus('done');
        }, 5000)
      } catch (err) {
        setAccountStatus('failed')
      }
    }
    if(props.location.search){
      setAccountStatus('activating')
      sendActivation(props.location.search.substring(7));
    } else {
      setAccountStatus('done');
    }
  }, [props.location.search])

  useEffect(() => {
    setError(undefined);
  }, [email, password]);

  const onClickLogin = async event => {
    openSpin();
    event.preventDefault();
    const creds = {
      email,
      password
    };

    const { history } = props;
    const { push } = history;

    setError(undefined);
    try {
      await dispatch(loginHandler(creds));
      push('/');
    } catch (apiError) {
      console.log(apiError.response);
      if (apiError.response !== undefined){
        setError(apiError.response.data.message);  
      } else {
        setError("Sistema temporariamente indisponível, tente mais tarde.");
      }      
      closeSpin();
    }
    closeSpin();
  };

  const { t } = useTranslation();

  if(accountStatus === 'failed') {
    return (
    <div className="container">
      <div className="alert alert-danger text-center">
        <div>
          <i className="material-icons" style={{ fontSize: '48px' }}>
            error
          </i>
        </div>
        {t('Ocorreu um erro ao tentar ativar a conta')}
      </div>
    </div>
    )
  }

  let message;
  if(accountStatus === 'activating') {
    message = (
      <div className="alert alert-info text-center">
        <Spinner />
        <span>{t('Por favor aguarde, seu usuários está sendo ativado')}</span>
      </div>
    )
  } else if (accountStatus === 'active'){
    message = (
      <div className="alert alert-success text-center">
        <div>
          <i className="material-icons" style={{ fontSize: '48px' }}>
            done_outline
          </i>
        </div>
        <span>{t('Usuário ativado com sucesso!')}</span>
      </div>
    )
  }

  

  const buttonEnabled = email && password;

  return ( 
    <div className="container loginbox">
      <SpinModal showModal={showSpin} />
      <div className="row">
      <div className="col-md-3"></div>

        <div className="col">
          {message && message}
          <div className=" loginbox">
          <h2 className="text-center">{t('Login')}</h2>
            <div className="card-body">
              <form>
                <Input label={t('E-mail')} onChange={event => setEmail(event.target.value)} />
                <Input label={t('Senha')} type="password" onChange={event => setPassword(event.target.value)} />
                {error && <div className="alert alert-danger">{error}</div>}
                <div className="text-center">
                  <ButtonWithProgress onClick={onClickLogin} disabled={!buttonEnabled || pendingApiCall} pendingApiCall={pendingApiCall} text={t('Login')} />
                </div>
              </form>
              <div className="text-center mt-3">
                <Link to="/password-reset-request" >{t('Esqueceu sua senha?')}</Link><br/>
                <Link to="/plan" style={{color: "#ef8e00"}}>{t('Cadastre-se')}</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3"></div>
      </div>
    </div>
  );
};

export default LoginPage;
