import React, { useState, useEffect, useRef } from "react";
import Input from "../components/Input";
import { useTranslation } from "react-i18next";
import ButtonWithProgress from "../components/ButtonWithProgress";
import { useApiProgress } from "../shared/ApiProgress";
import { logout, putChangePassword } from "../api/apiCalls";
import './PasswordResetPage.css';
import SpinModal from "./SpinModal";
import { useSelector, useDispatch } from "react-redux";

const PasswordChangePage = (props) => {
  const [form, setForm] = useState({
    passwordActual: null,
    password: null,
    passwordRepeat: null,
  });
  const { id } = useSelector((store) => ({ id: store.id }));
  const [errors, setErrors] = useState({});
  const [error, setError] = useState();
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [showSpin, setShowSpin] = useState(false);

  const closeSpin = () => {
    setShowSpin(false);
  };
  const openSpin = () => {
    setShowSpin(true);
  };
  
  let redirectTimer = useRef();

  useEffect(() => {
    if(passwordUpdated) {
       redirectTimer.current = setTimeout(() => {
        props.history.push("/");
      }, 5000);
    }
    return function cleanup() {
      clearTimeout(redirectTimer.current);
    }
  }, [passwordUpdated, props.history])

  useEffect(() => {
    
  }, [props.location.search, props.history]);

  const onChange = (event) => {
    const { name, value } = event.target;

    setErrors((previousErrors) => ({ ...previousErrors, [name]: undefined }));
    setForm((previousForm) => ({ ...previousForm, [name]: value }));
  };

  const onClick = async (event) => {
    openSpin();
    event.preventDefault();

    const { passwordActual, password } = form;

    const body = {
      passwordActual,
      password,
    };
    setErrors({});

    try {
      await putChangePassword(id, body);
      setPasswordUpdated(true);
      closeSpin();
    } catch (apiError) {
      closeSpin();
      if (apiError.response != undefined){
        setError(apiError.response.data.message);  
      } else {
        setError("Ocorreu um erro ao alterar a senha, tente novamente mais tarde.");
      }   
    }
  };

  const pendingApiCall = useApiProgress("put", "/api/1.0/user/change/password", true);

  const { t } = useTranslation();

  if (passwordUpdated) {
    return (
      <div className="container">
        <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 card p-0 mt-5">
          <div className="alert alert-success text-center m-0">
            <div>
              <i className="material-icons" style={{ fontSize: "48px" }}>
                done_outline
              </i>
            </div>
            <span>
              {t(
                "Sua senha foi atualizada. Você será redirecionado para tela inicial"
              )}
            </span>
          </div>
        </div>
      </div>
    );
  }

  const { password: passwordError } = errors;

  const { passwordActual: passwordActualError } = errors;

  let passwordRepeatError;
  if (form.password !== form.passwordRepeat) {
    passwordRepeatError = t("Password mismatch");
  }
  return (
    <div className="container restbox">
      <SpinModal showModal={showSpin} />
      <div className="row">
      <div className="col-md-3"></div>
        <div className="col">
      <div className="restbox">
        <h2 className="text-center">{t('Defina sua nova senha')}</h2>
        <div className="card-body">
          <form>
          <Input
              name="passwordActual"
              label={t("Senha Atual")}
              error={passwordActualError}
              onChange={onChange}
              type="password"
            />
            <Input
              name="password"
              label={t("Nova Senha")}
              error={passwordError}
              onChange={onChange}
              type="password"
            />
            <Input
              name="passwordRepeat"
              label={t("Repita a Senha")}
              error={passwordRepeatError}
              onChange={onChange}
              type="password"
            />
            {error && <div className="alert alert-danger">{error}</div>}
            <div className="text-center">
              <ButtonWithProgress
                onClick={onClick}
                disabled={pendingApiCall || passwordRepeatError !== undefined}
                pendingApiCall={pendingApiCall}
                text={t("Salvar")}
              />
            </div>
            
          </form>
        </div>
      </div>
      </div>
      <div className="col-md-3"></div>
      </div>
    </div>
  );
};

export default PasswordChangePage;
