import React from "react";
import { useSelector } from "react-redux";
import "./HomePage.css";
import { Redirect } from "react-router-dom";

const HomePage = () => {
  const { isLoggedIn, token } =
    useSelector((store) => ({
      isLoggedIn: store.isLoggedIn,
      id: store.id,
      username: store.username,
      password: store.password,
      image: store.image,
      token: store.tokenSqed,
    }));

  return (
    <div className="container">
      <div className="row">
        {isLoggedIn && token !== undefined ? (
          <div className="col">
            {token.token !== null ? (
              <iframe
                target="_top"
                title="sqed"
                src={
                  "https://www.sqeds.com/white-espacos-seguidos/token?" +
                  token.token
                }
                scrolling="no"
                frameborder="no"
                width="100%"
                height="1000px"
              ></iframe>
            ) : (
              "Não foi possível carregar os espaços para este usuário, entre em contato com: contato@conectarhub.com.br"
            )}
          </div>
        ) : (
          <Redirect to="/login" />
        )}
      </div>
    </div>
  );
};

export default HomePage;
