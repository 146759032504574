import axios from 'axios';

//axios.defaults.baseURL=


const api = axios.create({
  timeout: 120000,
  baseURL: "https://portal-api.conectarhub.com.br",
});

export const signup = body => {
  console.log(body);
  return api.post('/api/1.0/users', body);
};

export const sqedUserValidate = email => {
  return api.post('/api/1.0/usersqed/exists/'+email);
};

export const signUpSqedUser = body => {
  return api.post('/api/1.0/user/sqed/create', body);
};

export const validateSqedUser = body => {
  return api.post('/api/1.0/user/sqed/validate', body);
};

export const activate = token => {
  return api.post('/api/1.0/users/token/'+token);
}

export const login = creds => {
  return api.post('/api/1.0/auth', {...creds});
};

export const logout = () => {
  return api.post('/api/1.0/logout');
}

export const changeLanguage = language => {
  api.defaults.headers['accept-language'] = language;
};

export const getUsers = (page = 0, size = 10) => {
  return api.get(`/api/1.0/users?page=${page}&size=${size}`);
};

export const setAuthorizationHeader = ({ email, password, token, isLoggedIn }) => {
  if (isLoggedIn) {
    let authorizationHeaderValue;
    if(token) {
      authorizationHeaderValue = `Bearer ${token}`
    } else {
      authorizationHeaderValue = `Basic ${btoa(email + ':' + password)}`;
    }
    api.defaults.headers['Authorization'] = authorizationHeaderValue;
  } else {
    delete api.defaults.headers['Authorization'];
  }
};

export const getUser = username => {
  return api.get(`/api/1.0/users/${username}`);
};

export const updateUser = (username, body) => {
  return api.put(`/api/1.0/users/${username}`, body);
};

export const updateTokenSqedUser = (idUser, body) => {
  return api.put(`/api/1.0/users/sqed/token/${idUser}`, body);
};

export const deleteUser = id => {
  return api.delete(`/api/1.0/users/${id}`);
};

export const requestReset = email => {
  return api.post('/api/1.0/user/password', {email});
};

export const putPassword = body => {
  return api.put('/api/1.0/user/password', body);
};

export const putChangePassword = (idUser, body) => {
  return api.put('/api/1.0/user/change/password/'+idUser, body);
};

export const loginSqedUser = body => {
  return api.post('/api/1.0/user/sqed', body);
};

export const blockUser = (email, block) => {
  return api.post(`/api/1.0/users/block/${email}`, {block});
}

export const changeUserPlan = (id, plan) => {
  return api.post(`/api/1.0/users/plan/${id}`, {plan});
}

export const createPlan = body => {
  return api.post('/api/1.0/plan', body);
};

export const deletePlan = id => {
  return api.delete(`/api/1.0/plan/${id}`);
};

export const getAllPlans = () => {
  return api.get(`/api/1.0/plan`);
};

export const getPlan = id => {
  return api.get(`/api/1.0/plan/${id}`);
};

export const updatePlan = (id, body) => {
  return api.put(`/api/1.0/plan/${id}`, body);
};

export const getAddressByCEP = (cep) => {
  return api.get(`/api/1.0/address/${cep}`);
};