import React from 'react';

const SelectState = props => {
  const { label, error, name, onChange, type, defaultValue, value } = props;
  let className = 'form-control';

  if (type === 'file') {
    className += '-file';
  }

  if (error !== undefined) {
    className += ' is-invalid';
  }

  return (
    <div className="form-group">
      <label>{label}</label>
      <select className={className} name={name} onChange={onChange} defaultValue={defaultValue} value={value}>
                <option value="">Selecione</option>
                <option value="AC">Acre</option>
                <option value="AL">Alagoas</option>
                <option value="AP">Amapá</option>
                <option value="AM">Amazonas</option>
                <option value="BA">Bahia</option>
                <option value="CE">Ceará</option>
                <option value="ES">Espírito Santo</option>
                <option value="GO">Goiás</option>
                <option value="MA">Maranhão</option>
                <option value="MT">Mato Grosso</option>
                <option value="MS">Mato Grosso do Sul</option>
                <option value="MG">Minas Gerais</option>
                <option value="PA">Pará</option>
                <option value="PB">Paraíba</option>
                <option value="PR">Paraná</option>
                <option value="PE">Pernambuco</option>
                <option value="PI">Piauí</option>
                <option value="RJ">Rio de Janeiro</option>
                <option value="RN">Rio Grande do Norte</option>
                <option value="RS">Rio Grande do Sul</option>
                <option value="RO">Rondônia</option>
                <option value="RR">Roraima</option>
                <option value="SC">Santa Catarina</option>
                <option value="SP">São Paulo</option>
                <option value="SE">Sergipe</option>
                <option value="TO">Tocantins</option>
              </select> 
      <div className="invalid-feedback">{props.error}</div>
    </div>
  );
};

export default SelectState;
