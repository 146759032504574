import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { logoutSuccess } from "../redux/authActions";
import ProfileImageWithDefault from "./ProfileImageWithDefault";
import "./TopBar.css";

const TopBar = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { id, isLoggedIn, image, firstname, lastname, admin } = useSelector(
    (store) => ({
      isLoggedIn: store.isLoggedIn,
      id: store.id,
      username: store.username,
      image: store.image,
      firstname: store.firstname,
      lastname: store.lastname,
      email: store.email,
      admin: store.admin,
    })
  );

  const menuArea = useRef(null);

  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    document.addEventListener("click", menuClickTracker);
    return () => {
      document.removeEventListener("click", menuClickTracker);
    };
  }, [isLoggedIn]);

  const menuClickTracker = (event) => {
    if (menuArea.current === null || !menuArea.current.contains(event.target)) {
      setMenuVisible(false);
    }
  };

  const dispatch = useDispatch();

  const onLogoutSuccess = () => {
    dispatch(logoutSuccess());
    history.push("/login");
  };

  let links = <ul className="navbar-nav ml-auto"></ul>;
  if (isLoggedIn) {
    let dropDownClass = "dropdown-menu p-0 shadow";
    if (menuVisible) {
      dropDownClass += " show";
    }

    links = (
      <ul className="navbar-nav ml-auto" ref={menuArea}>
        <li className="nav-item dropdown">
          <div
            className="d-flex"
            style={{ cursor: "pointer" }}
            onClick={() => setMenuVisible(true)}
          >
            <ProfileImageWithDefault
              image={image}
              width="32"
              height="32"
              className="rounded-circle m-auto"
            />
            <span
              className="nav-link dropdown-toggle"
              style={{ color: "#fff" }}
            >
              {firstname} {lastname}
            </span>
          </div>
          <div className={dropDownClass}>
            <Link
              className="dropdown-item d-flex p-2"
              to={`/`}
              onClick={() => setMenuVisible(false)}
            >
              {t("Página Inicial")}
            </Link>
            <Link
              className="dropdown-item d-flex p-2"
              to={`/user/${id}`}
              onClick={() => setMenuVisible(false)}
            >
              {t("Meu Perfil")}
            </Link>
            {!admin && (
              <Link
                className="dropdown-item d-flex p-2"
                to={`/change/user/plan`}
                onClick={() => setMenuVisible(false)}
              >
                {t("Plano de Acesso")}
              </Link>
            )}
            {admin && (
              <Link
                className="dropdown-item d-flex p-2"
                to={`/userlist`}
                onClick={() => setMenuVisible(false)}
              >
                {t("Gerenciar Usuários")}
              </Link>
            )}
            {admin && (
              <Link
                className="dropdown-item d-flex p-2"
                to={`/management/plan`}
                onClick={() => setMenuVisible(false)}
              >
                {t("Gerenciar Planos")}
              </Link>
            )}
            <span
              className="dropdown-item  d-flex p-2"
              onClick={onLogoutSuccess}
              style={{ cursor: "pointer" }}
            >
              {t("Sair")}
            </span>
          </div>
        </li>
      </ul>
    );
  }

  return (
    <div className="topStyle">
      <nav className="navbar navbar-light container navbar-expand topDivBarMobile">
        {links}
      </nav>
    </div>
  );
};

export default TopBar;
