import React, { useEffect, useState } from "react";
import UserSignupPage from "../pages/UserSignupPage";
import LoginPage from "../pages/LoginPage";
import HomePage from "../pages/HomePage";
import UserPage from "../pages/UserPage";
import PasswordResetRequestPage from "../pages/PasswordResetRequestPage";
import PasswordResetPage from "../pages/PasswordResetPage";
import UserList from "../components/UserList";
import PlanList from "../pages/PlanList";
import PlanAddEdit from "../pages/PlanAddEdit";
import { useTranslation } from "react-i18next";
import logo from "../assets/logo-rodape.png";
import {
  HashRouter as Router,
  Route,
  Redirect,
  Switch,
  Link
} from "react-router-dom";
import TopBar from "../components/TopBar";
import { useSelector, useDispatch } from "react-redux";
import { loginHandler, logoutSuccess } from "../redux/authActions";
import Spinner from "../components/Spinner";
import UserSignupPlanPage from "../pages/UserSignupPlanPage";
import UserPlanPage from "../pages/UserPlanPage";
import PasswordChangePage from "../pages/PasswordChangePage";
import "./App.css";
import CookieConsent from "react-cookie-consent";

const App = () => {
  const dispatch = useDispatch();
  const { id, email, password, isLoggedIn, token, admin } = useSelector(
    (store) => ({
      email: store.email,
      password: store.password,
      isLoggedIn: store.isLoggedIn,
      token: store.token,
      admin: store.admin,
      id: store.id,
    })
  );
  const [validatingLogin, setValidatingLogin] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    if (isLoggedIn && token) {
      const creds = {
        email,
        password,
      };
      dispatch(loginHandler(creds))
        .then((s) => setValidatingLogin(false))
        .catch((c) => setValidatingLogin(false));
    } else {
      setValidatingLogin(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (validatingLogin) {
    return <Spinner />;
  }
  const onLogoutSuccess = () => {
    dispatch(logoutSuccess());
  };

  return (
    <div>
      <Router>
        <TopBar />
        <Switch>
          <Route exact path="/" component={HomePage} />
          {!isLoggedIn && <Route path="/login" component={LoginPage} />}
          <Route path="/signup" component={UserSignupPage} />
          {isLoggedIn && <Route path="/user/change/password" component={PasswordChangePage} />}
          
          <Route path="/plan" component={UserSignupPlanPage} />
          {isLoggedIn && <Route path="/user/:userid" component={UserPage} />}
          {isLoggedIn && (
            <Route path="/change/user/plan" component={UserPlanPage} />
          )}
          {admin && <Route path="/userlist" component={UserList} />}
          {admin && <Route path="/management/plan" component={PlanList} />}
          {admin && <Route path="/management/add" component={PlanAddEdit} />}
          {admin && (
            <Route path="/management/edit/:id" component={PlanAddEdit} />
          )}
          <Route
            path="/password-reset-request"
            component={PasswordResetRequestPage}
          />
          <Route path="/password-reset" component={PasswordResetPage} />
          <Redirect to="/" />
        </Switch>
        {isLoggedIn && (
          <div
            className="topDivBar"
            style={{
              position: "fixed",
              left: "0px",
              top: "0px",
              right: "0px",
              backgroundColor: "#00000089",
              height: "60px",
              textAlign: "right",
            }}
          >
            <div
              style={{ width: "100%", top: "10px" }}
              className="navbar"
            >
              <div style={{ width: "20%" }}></div>
              <Link
                style={{
                  display: "inline-block",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontSize: "16px",
                  color: "#fff",
                  outline: "none",
                  margin: "0px 18px",
                }}
                to={`/`}
              >
                {t("Página Inicial")}
              </Link>
              {!admin && (
                <Link
                  style={{
                    display: "inline-block",
                    alignItems: "center",
                    justifyContent: "space-between",
                    fontSize: "16px",
                    color: "#fff",
                  }}
                  to={`/change/user/plan`}
                >
                  {t("Plano de Acesso")}
                </Link>
              )}
              {admin && (
                <Link
                  style={{
                    display: "inline-block",
                    alignItems: "center",
                    justifyContent: "space-between",
                    fontSize: "16px",
                    color: "#fff",
                  }}
                  to={`/userlist`}
                >
                  {t("Gerenciar Usuários")}
                </Link>
              )}
              {admin && (
                <Link
                  style={{
                    display: "inline-block",
                    alignItems: "center",
                    justifyContent: "space-between",
                    fontSize: "16px",
                    color: "#fff",
                  }}
                  to={`/management/plan`}
                >
                  {t("Gerenciar Planos")}
                </Link>
              )}
              <Link
                style={{
                  display: "inline-block",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontSize: "16px",
                  color: "#fff",
                  outline: "none",
                  margin: "0px 18px",
                }}
                to={`/user/${id}`}
              >
                {t("Meu Perfil")}
              </Link>
              <span
                style={{
                  display: "inline-block",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontSize: "16px",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={onLogoutSuccess}
              >
                {t("Sair")}
              </span>
              <div style={{ width: "20%" }}></div>
            </div>
          </div>
        )}
        
        <div
          style={{
            position: "fixed",
            left: "0px",
            bottom: "0px",
            right: "0px",
            backgroundColor: "#706f6f",
            height: "50px",
            textAlign: "right",
          }}
        >
          <Link className="navbar-brand" to="/">
            <img src={logo} width="150" height="34" alt="Logo" />
          </Link>
        </div>
      </Router>
      <CookieConsent
        location="bottom"
        buttonText="Aceitar"
        cookieName="conectarhub-accept-cookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "16px", backgroundColor: "#9ACD32" }}
        declineButtonStyle={{ color: "#4e503b", fontSize: "16px", backgroundColor: "#A9A9A9" }} 
        expires={150}
        enableDeclineButton
        declineButtonText="Declinar"
      >
          
        Este portal utiliza cookie para uma boa experiência do usuário.{" "}
      </CookieConsent>
    </div>
  );
};

export default App;
