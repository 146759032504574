import React, { Component } from "react";
import { loginSqedUser } from "../api/apiCalls";
import { Modal } from "react-bootstrap";
import { format } from "date-fns";

class LoginForm extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      smShow: false,
      email: "",
      password: "",
      mode: "login",
      token: "",
      user: "",
      error: false,
    };
  }

  setMode = (mode) => {
    this.setState({
      mode,
    });
  };

  userChange = (e) => {
    this.setState({ email: e.target.value });
  };
  passChange = (e) => {
    this.setState({ password: e.target.value });
  };

  reg = () => {
    this.props.onRegister(this.state.email);
  };

  onSubmit = async () => {
    this.props.onFind();
    this.props.onClose();
    //this.reg();
    const body = {
      email: this.state.email,
      password: this.state.password,
    };
    await loginSqedUser(body).then(async (result) => {
      if (!result.data) {
        this.props.onFindClose();
        this.setState({ error: true });
        this.props.onErrorOpen();
      } else {
        this.setState({ error: false });
        var date = new Date(result.data.user.user.birthdate);
        var formattedDate = format(date, "yyyy-MM-dd");
        this.props.onRegister(
          result.data.user.user.email,
          result.data.user.user.name,
          result.data.user.user.surname,
          formattedDate,
          result.data.user.user.gender,
          result.data.token.token
        );
        
      }
    });
    
  };

  renderLogin = () => {
    return (
      <div>
        <form className="form-horizontal form-loanable">
          {this.state.error ? (
            <div className="alert alert-danger alert-sm">
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              <span className="fw-semi-bold">Erro:</span> Usuário e/ou senha
              inválido!
            </div>
          ) : (
            ""
          )}
          <fieldset>
            <div className="form-group has-feedback required">
              <label htmlFor="login-email" className="col-sm-5">
                Usuário
              </label>
              <div className="col-sm-7">
                <span
                  className="form-control-feedback"
                  aria-hidden="true"
                ></span>
                <input
                  type="text"
                  name="email"
                  id="login-email"
                  className="form-control"
                  placeholder="Usuário Sqed"
                  onChange={this.userChange}
                  value={this.state.email}
                  required
                />
              </div>
              {/* console.log('error email ::: ' + JSON.stringify(errors)) */}
            </div>
            <div className="form-group has-feedback required">
              <label htmlFor="login-password" className="col-sm-5">
                Senha
              </label>
              <div className="col-sm-7">
                <span
                  className="form-control-feedback"
                  aria-hidden="true"
                ></span>
                <div className="login-password-wrapper">
                  <input
                    type="password"
                    name="password"
                    id="login-password"
                    className="form-control"
                    placeholder="*****"
                    required
                    onChange={this.passChange}
                    value={this.state.password}
                  />
                </div>
              </div>
            </div>
          </fieldset>
          <div className="form-action">
            <button
              type="button"
              className="btn btn-lg btn-primary btn-left"
              onClick={this.onSubmit}
            >
              Buscar Dados e Vincular <span className="icon-arrow-right2 outlined"></span>
            </button>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <div>
        <Modal
          show={this.props.showModal}
          onHide={this.props.onClose}
          bsSize="large"
          centered
        >
          <Modal.Header closeButton={true}>
            <h2>Login na Sqed</h2>
          </Modal.Header>
          <Modal.Body>{this.renderLogin()}</Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default LoginForm;
