import React, { useState, useEffect } from "react";
import { getUsers, blockUser } from "../api/apiCalls";
import { useTranslation } from "react-i18next";
import UserListItem from "./UserListItem";
import { useApiProgress } from "../shared/ApiProgress";
import Spinner from "./Spinner";
import SpinModal from "../pages/SpinModal";
import { CSVLink } from "react-csv";
import ButtonWithProgress from "./ButtonWithProgress";

const UserList = () => {
  const [page, setPage] = useState({
    content: [],
    size: 20,
    page: 0,
    totalPages: 0,
  });

  const onClickSave = async (email, blocked) => {
    openSpin();
    await blockUser(email, !blocked);
    closeSpin();
    window.location.reload();
  };

  const headers = [
    { label: "CPF", key: "cpf" },
    { label: "Nome", key: "firstname" },
    { label: "Sobrenome", key: "lastname" },
    { label: "E-mail", key: "email"},
    { label: "CEP", key: "cep"},
    { label: "Logradouro", key: "street"},
    { label: "Número", key: "number"},
    { label: "Bairro", key: "district"},
    { label: "Estado", key: "state"},
    { label: "País", key: "country"},
    { label: "Data Nascimento", key: "birthdate"},
    { label: "Nome Fantasia", key: "pjNomeFantasia"},
    { label: "Razão Social", key: "pjRazaoSocial"},
    { label: "CNPJ", key: "pjcnpj"},
    { label: "Data Fundação", key: "pjFundacao"},
    { label: "Plano Assinatura", key: "plan"},
    { label: "Tipo Pagamento", key: "paymentType"},
  ];

  const csvReport = {
    data: page.content,
    headers: headers,
    filename: 'export_usuarios.csv'
  };

  const [loadFailure, setLoadFailure] = useState(false);
  const [showSpin, setShowSpin] = useState(false);
  const pendingApiCall = useApiProgress("get", "/api/1.0/users?page=0&size=20");

  useEffect(() => {
    loadUsers();
  }, []);

  const closeSpin = () => {
    setShowSpin(false);
  };
  const openSpin = () => {
    setShowSpin(true);
  };

  const onClickNext = () => {
    const nextPage = page.page + 1;
    loadUsers(nextPage);
  };

  const onClickPrevious = () => {
    const previousPage = page.page - 1;
    loadUsers(previousPage);
  };

  const loadUsers = async (page) => {
    openSpin();
    setLoadFailure(false);
    try {
      const response = await getUsers(page);
      setPage(response.data);
    } catch (error) {
      setLoadFailure(true);
      closeSpin();
    }
    closeSpin();
  };

  const { t } = useTranslation();
  const { content: users, page: currentPage, totalPages } = page;

  const first = currentPage === 0;
  const last = totalPages !== 0 ? currentPage === totalPages - 1 : true;

  let actionDiv = (
    <div>
      {first === false && (
        <button className="btn btn-sm btn-light" onClick={onClickPrevious}>
          {t("Anterior")}
        </button>
      )}
      {last === false && (
        <button
          className="btn btn-sm btn-light float-right"
          onClick={onClickNext}
        >
          {t("Próxima")}
        </button>
      )}
    </div>
  );

  if (pendingApiCall) {
    actionDiv = <Spinner />;
  }

  let content = <div/>;
  if(pendingApiCall && users.length === 0) {
    content = <Spinner />;
  } else {
    content = users.length > 0 && (
      <div className="card">
        <h3 className="card-header text-center" style={{color:'#fff'}}>{t("Usuários")}</h3>
        <div className="list-group-flush">
          <table className="table table-striped">
            <thead>
              <tr>
                <th style={{ width: "30%" }}>Nome</th>
                <th style={{ width: "30%" }}>Sobrenome</th>
                <th style={{ width: "30%" }}>E-mail</th>
                <th style={{ width: "30%" }}>Plano</th>
                <th style={{ width: "30%" }}>Status</th>
                <th style={{ width: "10%" }}></th>
              </tr>
            </thead>
          {users.map((user) => (
              <tr key={user.id}>
                <td>{user.firstname}</td>
                <td>{user.lastname}</td>
                <td>{user.email}</td>
                <td>{user.plan}</td>
                <td>{user.inactive ? 'Inativo': 'Ativo'}</td>
                <td style={{ whiteSpace: "nowrap" }}>
                <button
                  className="btn btn-primary"
                  onClick={() => onClickSave(user.email, user.blocked)}
                >
                {<>{!user.blocked ? "Desativar" : "Ativar"}</>}
                </button>
                </td>
              </tr>
            ))}
          </table>
        </div>
        {actionDiv}
        {loadFailure && (
          <div className="text-center text-danger">{t("Erro ao carregar os usuários cadastrados")}</div>
        )}
      </div>
    )
  }

  if (users.length === 0){
    content = (
      <div className="card">
      <h3 className="card-header text-center" style={{color:'#fff'}}>{t("Usuários")}</h3>
      <div className="list-group-flush">
        Nenhum usuário cadastrado!
      </div>
      {actionDiv}
      {loadFailure && (
        <div className="text-center text-danger">{t("Erro ao carregar os usuários cadastrados")}</div>
      )}
    </div>
    );
  }

  return (
    <div className="container registrybox">
      <SpinModal showModal={showSpin} />
      {content}
      <CSVLink {...csvReport}>Exportar para CSV</CSVLink>
    </div>
  );
};

export default UserList;
