import React from 'react';

const Input = props => {
  const { label, error, name, onChange, type, defaultValue, onBlur, value } = props;
  let className = 'form-control '+ name;

  if (type === 'file') {
    className += '-file';
  }

  if (error !== undefined) {
    className += ' is-invalid';
  }

  return (
    <div className="form-group">
      <label>{label}</label>
      <input className={className} name={name} onChange={onChange} type={type} onBlur={onBlur} defaultValue={defaultValue} value={value} data-date-format="dd/MM/yyyy"/>
      <div className="invalid-feedback">{props.error}</div>
    </div>
  );
};

export default Input;
