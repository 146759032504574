import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllPlans, deletePlan } from "../api/apiCalls";
import { useTranslation } from "react-i18next";
import SpinModal from "./SpinModal";

const PlanList = () => {
  const [plans, setPlans] = useState(null);
  const [showSpin, setShowSpin] = useState(false);
 
  const loadPlans = async () => {
    openSpin();
    try {
      const response = await getAllPlans();
      setPlans(response.data.content);
    } catch (error) {
      closeSpin();
    }
    closeSpin();
  };

  useEffect(() => {
    loadPlans();
  }, []);

  const closeSpin = () => {
    setShowSpin(false);
  };
  const openSpin = () => {
    setShowSpin(true);
  };

  const { t } = useTranslation();

  const deleteUser = (id) => {
    setPlans(
      plans.map((x) => {
        if (x.id === id) {
          x.isDeleting = true;
        }
        return x;
      })
    );
    deletePlan(id).then(() => {
      setPlans((plans) => plans.filter((x) => x.id !== id));
    });
  };

  return (
    <div className="container registrybox">
      <SpinModal showModal={showSpin} />
      <div className="card">
        <h3 className="card-header text-center" style={{ color: "#fff" }}>
          {t("Planos de Acesso")}
        </h3>
        <br />
        <div>
          <Link to={`/management/add`} className="btn btn-sm btn-success mb-2">
            Adicionar Plano
          </Link>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th style={{ width: "30%" }}>Nome</th>
              <th style={{ width: "30%" }}>Tag</th>
              <th style={{ width: "30%" }}>Valor</th>
              <th style={{ width: "10%" }}></th>
            </tr>
          </thead>
          <tbody>
            {plans != null
              ? plans &&
                plans.map((plan) => (
                  <tr key={plan.id}>
                    <td>{plan.name}</td>
                    <td>{plan.tag}</td>
                    <td>{plan.value}</td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <Link
                        to={`/management/edit/${plan.id}`}
                        className="btn btn-sm btn-primary mr-1"
                      >
                        Editar
                      </Link>
                      <button
                        onClick={() => deleteUser(plan.id)}
                        className="btn btn-sm btn-danger btn-delete-user"
                        disabled={plan.isDeleting}
                      >
                        {plan.isDeleting ? (
                          <span className="spinner-border spinner-border-sm"></span>
                        ) : (
                          <span>Deletar</span>
                        )}
                      </button>
                    </td>
                  </tr>
                ))
              : ""}
            {!plans && (
              <tr>
                <td colSpan="4" className="text-center">
                  <div className="spinner-border spinner-border-lg align-center"></div>
                </td>
              </tr>
            )}
            {plans && !plans.length && (
              <tr>
                <td colSpan="4" className="text-center">
                  <div className="p-2">Nenhum plano cadastrado</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PlanList;
