import React, { useState, useEffect } from 'react';
import ProfileCard from '../components/ProfileCard';
import { getUser } from '../api/apiCalls';
import { useSelector, useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useApiProgress } from '../shared/ApiProgress';
import Spinner from '../components/Spinner';
import './UserPage.css';

const UserPage = () => {
  const [user, setUser] = useState({});
  const [notFound, setNotFound] = useState(false);

  const { id: loggedInUserId } = useSelector((store) => ({ id: store.id }));

  //const { userid } = useParams();

  const { t } = useTranslation();

  const pendingApiCall = useApiProgress('get', '/api/1.0/users/' + loggedInUserId, true);

  useEffect(() => {
    setNotFound(false);
  }, [user]);

  useEffect(() => {
    const loadUser = async () => {
      try {
        setNotFound(false);
        const response = await getUser(loggedInUserId);
        setUser(response.data);
      } catch (error) {
        setNotFound(true);
      }
    };
    loadUser();
  }, [loggedInUserId]);

  if (notFound) {
    return (
      <div className="container">
        <div className="alert alert-danger text-center">
          <div>
            <i className="material-icons" style={{ fontSize: '48px' }}>
              error
            </i>
          </div>
          {t('Usuário não encontrado')}
        </div>
      </div>
    );
  }

  // eslint-disable-next-line eqeqeq
  if (pendingApiCall || user.id != loggedInUserId) {
    return <Spinner />;
  }

  return (
    <div className="container registrybox">
      <div className="row">
      <div className="col userpage"></div>
        <div className="col-md-6">
          <ProfileCard user={user} />
        </div>
        <div className="col userpage"></div>
      </div>
    </div>
  );
};

export default UserPage;
