import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ButtonWithProgress from "../components/ButtonWithProgress";
import { useApiProgress } from "../shared/ApiProgress";
import { createPlan, getPlan, updatePlan } from "../api/apiCalls";
import SpinModal from "./SpinModal";

function PlanAddEdit({ match }) {
  const { id } = match.params;
  const isAddMode = !id;

  const [errors, setErrors] = useState({});
  const [showSpin, setShowSpin] = useState(false);
  const [externalFailure, setExternalFailure] = useState(false);
  const history = useHistory();
  const [form, setForm] = useState({
    tag: "",
    name: "",
    description: "",
    value: "",
  });
  const closeSpin = () => {
    setShowSpin(false);
  };
  const openSpin = () => {
    setShowSpin(true);
  };
  const onChange = (event) => {
    const { name, value } = event.target;

    setErrors((previousErrors) => ({ ...previousErrors, [name]: undefined }));
    setForm((previousForm) => ({ ...previousForm, [name]: value }));
  };

  const pendingApiCallPlan = useApiProgress("post", "/api/1.0/plans");

  const onClickSavePlan = async (event) => {
    openSpin();
    event.preventDefault();

    const { name, description, value } = form;
    var tagClean = name.replace(/[^a-zA-Z ]/g, "");
    const body = { tag: tagClean.replaceAll(" ", ""), name, description, value };

    setExternalFailure();
    setErrors({});

    try {
      if (!isAddMode) {
        await updatePlan(id, body);
      } else {
        await createPlan(body);
      }
      history.push("/management/plan");
    } catch (error) {
      closeSpin();
      console.log(error);
      const errorStatus = error.response.status;
      if (errorStatus === 400) {
        setErrors(error.response.data.validationErrors);
      } else if (errorStatus === 502) {
        setExternalFailure(true);
      }
    }
    closeSpin();
  };

  useEffect( () => {
    if (!isAddMode) {
      getPlan(id).then((result)=> {
        setForm(result.data.content);
      });
      
    }
  }, [id, isAddMode]);

  return (
    <form onSubmit="">
      <SpinModal showModal={showSpin} />
      <div className="container registrybox">
        <div className="card">
          <h3 className="card-header text-center" style={{ color: "#fff" }}>
            {isAddMode ? "Adicionar Plano" : "Editar Plano"}
          </h3>

          <div className="form-row">
            <div className="form-group col-3">
              <label>Tag</label>
              <input
                name="tag"
                defaultValue={form.tag}
                type="text"
                onChange={onChange}
                readOnly
                className={`form-control`}
              />
              <div className="invalid-feedback"></div>
            </div>
            <div className="form-group col-6">
              <label>Nome</label>
              <input
                name="name"
                defaultValue={form.name}
                onChange={onChange}
                type="text"
                className={"form-control "}
              />
              <div className="invalid-feedback"></div>
            </div>
            <div className="form-group col-3">
              <label>Valor</label>
              <input
                name="value"
                defaultValue={form.value}
                onChange={onChange}
                type="text"
                className={"form-control "}
              />
              <div className="invalid-feedback"></div>
            </div>
            <div className="form-group col-7">
              <label>Descrição</label>
              <textarea
                name="description"
                defaultValue={form.description}
                onChange={onChange}
                type="textarea"
                className={`form-control`}
                rows={5}
              />
              <div className="invalid-feedback"></div>
            </div>
          </div>

          <div className="form-group">
            <ButtonWithProgress
              onClick={onClickSavePlan}
              disabled={pendingApiCallPlan}
              pendingApiCall={pendingApiCallPlan}
              text={"Salvar"}
            />
            <Link to={"/management/plan"} className="btn btn-link">
              Cancel
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
}

export default PlanAddEdit;
