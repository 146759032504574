import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import "./UserPlanPage.css";
import Modal from "../components/Modal";
import { changeUserPlan, getAllPlans } from "../api/apiCalls";
import { useApiProgress } from "../shared/ApiProgress";
import { updatePlanSuccess } from "../redux/authActions";
import { useHistory } from "react-router-dom";
import SpinModal from "./SpinModal";

const UserPlanPage = (props) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalRegVisible, setModalRegVisible] = useState(false);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const { id: loggedInUserId } = useSelector((store) => ({ id: store.id }));
  const [showSpin, setShowSpin] = useState(false);
  const history = useHistory();
  const { plan: loggedInUserPlan } = useSelector((store) => ({
    plan: store.plan,
  }));
  const [newPlan, setNewPlan] = useState();
  const dispatch = useDispatch();

  const [plans, setPlans] = useState(null);

  const closeSpin = () => {
    setShowSpin(false);
  };
  const openSpin = () => {
    setShowSpin(true);
  };

  useEffect(() => {
    loadPlans();
  }, []);

  const loadPlans = async () => {
    openSpin();

    try {
      const response = await getAllPlans();
      setPlans(response.data.content);
    } catch (error) {
      closeSpin();
    }

    closeSpin();
  };

  const onClickCancel = () => {
    setModalVisible(false);
  };

  const onClickCancelCancel = () => {
    setModalCancelVisible(false);
  };

  const onClickCompleteUser = async (event) => {
    history.push(`/user/${loggedInUserId}`);
  }

  const onClickPlanUser = async (event) => {
    try {
      openSpin();
      event.preventDefault();
      setModalVisible(false);
      setModalCancelVisible(false);
      await changeUserPlan(loggedInUserId, newPlan);
      dispatch(updatePlanSuccess({ plan: newPlan }));
      closeSpin();
    } catch (error) {
      setModalRegVisible(true);
      closeSpin();
    }
    
  };

  const pendingApiCallUpdateUser = useApiProgress(
    "post",
    `/api/1.0/users/plan/${loggedInUserId}`,
    true
  );

  return (
    <div className="container registrybox">
      <SpinModal showModal={showSpin} />
      <div className="container bootstrap snippets bootdey">
        <div className="row content">
        {plans != null
              ? plans &&
                plans.map((plan) => (
          <div className="col-md-4  text-center">
            <div className="plan" style={{height: '400px'}}>
              <h2 className="bronze-text">{plan.name}</h2>
              <p className="bronze-text price">{plan.value}</p>
              <p style={{whiteSpace: 'pre-line'}}>{plan.description}</p>
              
              <p>
                <br />{" "}
              </p>
              <p>
                <br />{" "}
              </p>
              {loggedInUserPlan !== plan.tag ? (
                <button
                  style={{ position: "absolute", bottom:"20px",  left:"10%", right:"10%", width:"80%"}}
                  className="btn btn-pricing"
                  onClick={() => {
                    setNewPlan(plan.tag);
                    setModalVisible(true);
                  }}
                >
                  {t("Alterar")}
                </button>
              ) : (
                <div style={{ position: "absolute", bottom:"30px",  left:"10%", right:"10%", width:"80%" }}>
                  <b>(Meu plano atual)</b>
                </div>
              )}
            </div>
          </div>)) : ""}
        </div>
      </div>
      <Modal
        visible={modalCancelVisible}
        title={t("Cancelar Plano")}
        okButton={t("Cancelar Plano")}
        onClickCancel={onClickCancelCancel}
        onClickOk={onClickPlanUser}
        centered
        message={t("Você tem certeza que deseja cancelar seu plano?")}
        pendingApiCall={pendingApiCallUpdateUser}
      />
      <Modal
        visible={modalVisible}
        title={t("Alterar Plano")}
        okButton={t("Alterar Plano")}
        onClickCancel={onClickCancel}
        onClickOk={onClickPlanUser}
        centered
        message={t("Você tem certeza que deseja alterar seu plano?")}
        pendingApiCall={pendingApiCallUpdateUser}
      />
      <Modal
        visible={modalRegVisible}
        title={t("Atenção!")}
        okButton={t("Completar")}
        onClickCancel={onClickCancel}
        onClickOk={onClickCompleteUser}
        centered
        message={t("Para que você possa escolher um plano pago, você precisa primeiro completar os seus dados, deseja completar agora?")}
        pendingApiCall={pendingApiCallUpdateUser}
      />      
    </div>
  );
};

export default UserPlanPage;
